<template>
  <ModalMain
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-2xl tracking-wide font-semibold mb-2 mt-5 text-center animate-slide-down-fade-in01s">Why connect?</h1>
          <div class="text-slate-100 mt-4 mb-8 leading-tight animate-slide-down-fade-in04s text-center">
            <p>MetaWin runs on the blockchain. Taking part requires a personal signature. No private data is shared with us. We will NEVER ask for an ‘Approve All’ state.</p>
          </div>

          <ButtonConnect size="md"/>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapState } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: 'ModalWhyConnectMain',
  data() {
    return {};
  },
  computed: {
    ...mapWritableState(useUiStore, ['showWhyConnectModal',]),
    ...mapState(useAuthStore, ['isUserLogged',]),
  },
  watch: {
    isUserLogged(val) {
      if (val) { this.close(); }
    },
  },
  mounted() {
    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'WhyConnect', });
  },
  methods: {
    close() {
      this.showWhyConnectModal = false;
    },
  },
});
</script>
